.blog-entry, .blog {
    padding-bottom: 5em; 
    padding-top: 20rem;
    time {
        font-size: var(--scale-1);
        color: #B6BCC9;
        letter-spacing: 2.5px;
        font-weight: 800;
        text-transform: capitalize;
    }
    h1,h2, h2 > a {
        color: var(--blue-dark);
    }
    h3 {
        color: var(--grey-tint);
    }
    figure {
        margin: 0;
        img {
            max-width: 100%;
        }
    }
    .blob {
        padding-left: 2rem;
        margin-bottom: 3rem;
    }
    hr {
        border-color: var(--grey-tint);
        border-width: 0.5px;
        opacity: 0.2;
        margin-top: 3rem;
        margin-bottom: 3rem;

    }
    article {  
        padding-bottom: rem;  
        p a {
            @include arrow;
            font-weight: 700;
        }
    }
    .article-nav > h2{
        overflow: hidden;
        text-overflow: ellipsis;

    }
    .post {
        margin-bottom: 5.7rem;
    }
}