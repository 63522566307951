.animate {
	.run {
        transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
        transition-property: transform, opacity;
        transform-origin: center center;
        transition-delay: inherit;
	}

	&.off {
		.up {
			transform: translate3d(0, 50px, 0);
			opacity: 0;
		}
	}
    
	&.in {
        .up {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
    @for $i from 0 to 5 {
        .delay-#{$i} {
            transition-delay: 0.25s * $i;
        }
    }
}
