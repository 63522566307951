header {
    z-index: 2;
    position: fixed;
    width: 100vw;
    // background: rgba(#fcfcfc,0.9);
    background-color: rgba(#fff, 0.9);
    padding-top: 1.6rem;
    padding-bottom: 1rem;
    .nav {
        transition: height 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
        height: 16rem;
        flex-flow: unset;
        @include bp(sm) {
            height: 6rem;
        }
        &.small {
            height: 6rem;
        }
    }
    font-size: calc(var(--scale0) - 0.2rem);
    @include bp(md) {
    	font-size: var(--scale-1);
    }
    @include bp(sm) {
    	font-size: var(--scale0);
    }
    text-transform: uppercase;
    font-weight: 500;
    color: var(--blue-darker);
    .separator {
        color: var(--grey-light);
        @include bp(sm) {
        	display: none;
        }
    }
    ul {
        justify-content: flex-end;
        display: flex;
        align-content: middle;
        padding-right: 4rem;
        &.lang {
            margin-bottom: 0;
            margin-top: 0;
            
            @include bp(sm) {
                z-index: 999;
                padding-right: 2.4rem;
                width: 100%;
                display: flex;
            }
        }
        &.menu {
            @include bp(sm) {
                overflow: hidden;
                display: block;
                transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s,
                            transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.01s;
                opacity: 0;
                transform: scaleY(0);
                li {
                    margin-bottom: 2rem;
                }
            }
        }
        li{
            margin-right: 3%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    a {
        color: inherit;
    }
    
    input[type='checkbox']{
        visibility: hidden;
        @include bp(sm) {
            visibility: visible;
        }
        right: 2.4rem;
        top: 5rem;
        position: absolute;
        width: 40px;
        height: 32px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        // &:hover {
        //     & ~ nav {
        //         height: 100vh;
        //     }
        //     & ~ nav div .menu {
        //         opacity: 1;
        //     }
        // }
        &:checked{
            @include bp(sm) {
                & ~ nav {
                    height: 100vh;
                }
                & ~ nav div .menu {
                    transform: scaleY(1);
                    opacity: 1;
                    margin-bottom: 0;
                }
                & ~ nav div a .logo {
                    transform: scaleY(1);
                    margin-top: 0;
                }
                & ~ .burger span {
                    opacity: 1;
                    transform: rotate(45deg) translate(-2px, -1px);
                }
                & ~ .burger span:nth-last-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
                & ~ .burger span:nth-last-child(2) {
                    transform: rotate(-45deg) translate(0, -1px);
                }
            }
        }
    }
    .burger {
        visibility: hidden;

        @include bp(sm) {
        visibility: visible;
        }
        z-index: 1;
        display: block;
        width: 36px;
        height: 24px;
        position: absolute;
        right: 2.4rem;
        top: 5rem;
        span {
            display: block;
            width: 100%;
            height: 4px;
            margin-bottom: 5px;
            position: relative;

            background: var(--blue);
            border-radius: 3px;

            z-index: 1;

            transform-origin: 28px 0px;

            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                        opacity 0.55s ease;
            &:first-child {
            	transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
            	transform-origin: 50% 0%;
            }
        }
    }
}
.logo {
    transform-origin: left;
    transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition-property: transform;
    width: dynamic-size(40.4, 9);
    margin-top: 10px;
    transform: scale(1);
    transform-origin: 0 50%;
    @include bp(sm) {
    	transform: scale(0.8);
    }
    &.scrolled {
        // height: 49px;
        // width: 74px;
        transform: scale(0.5);
        @include bp(sm) {
        	transform: scale(0.8);
        }
    }
}