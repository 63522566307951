*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    // font-size: 1.125em;
    // background: #fcfcfc;
    overflow-x: hidden;
    width: 100vw;
    // scroll-behavior: smooth;
}

body {
    font-family: var(--font-family), sans-serif;
    color: var(--blue-darker);

}

.content {
    max-width: var(--max-width);
    margin: 0 auto;

}

li {
    list-style: none;
}

a {
    color: var(--blue);
    text-decoration: none;
    font-size: inherit;
    letter-spacing: 0.14px;
    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    #contacto &, footer &, .success & {
        @include arrow;
    }
    &:hover, &.active{
        color: var(--teal);
    }

}

textarea {
	resize: none;
}