:root {
  // Colors
  --blue: #1859DD;
  --blue-dark: #08348C;
  --blue-darker: #25314A;
  --teal: #00EA95;
  --teal-dark: #06C982;
  --grey-dark: #4C5464;
  --grey: #717D96;
  --grey-light: #DAE7FF;
  --grey-tint: #B6BCC9;

  --yellow: #F5C523;
  --cyan: #00C6FF;
  --pink: #FF17CC;

  --top-distance: 6rem;

  // Sizes
  --max-width: 1280px; 

  //Typography
  --font-family: "Work Sans";  
  --ratio: 1.333;
  --base-font-size: 1.8rem;
  --scale0: calc(1.4rem + (2.4 - 1.4) * ((100vw - 375px)/(1280 - 375)));
  --scale-1: calc(var(--scale0)/ var(--ratio));
  --scale1: calc(var(--scale0)* var(--ratio));
  --scale2: calc(var(--scale1)* var(--ratio));
  --scale3: calc(var(--scale2)* var(--ratio));
  --scale4: calc(var(--scale3)* var(--ratio));
}

@function dynamic-size($value, $value-min) {
  $value-min: $value/2 !default;
  @return calc(#{$value-min}rem + (#{$value} - #{$value-min}) * ((100vw - 375px)/(1280 - 375)));
}
// @function dynamic-size($value-max, $value-min) {
//   @return calc(#{$value-min}rem + (#{$value-max} - #{$value-min}) * ((100vw - 375px)/(1280 - 375)));
// }

@mixin arrow {
	&::after {
		content: "→";
		color: inherit;
		margin-left: 4px;
		transition: margin-left 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	&:hover {
		color: var(--teal);

		&::after {
			margin-left: 8px;
			transition: margin-left 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		}
	}
}