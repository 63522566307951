
p {
    margin-bottom: 1.3rem;
    // font-size: $scale0;
    font-size: var(--scale0);
    line-height: 1.85;
}

h1,
h2,
h3,
h4 {
    margin: 1.414rem 0 0.5rem;
    font-weight: 100%;
    line-height: 1.2;
    color: var(--teal);
}

h1 {
    margin-top: 0;
    font-size: var(--scale4);
    @include bp(xs){
        font-size: var(--scale3);
    }
}

h2 {
    margin-top: 0;
    font-size: var(--scale2);
}

h3 {
    font-size: var(--scale-1);
    text-transform: uppercase;
    letter-spacing: 0.25rem;
    color: var(--blue);
    font-weight: 900;
}

h4 {
    font-size: var(--scale0);
    font-weight: 800;
    color: var(--grey-dark);
    text-transform: uppercase;

}

small,
.font_small {
    font-size: 0.75rem;
}

blockquote {
    font-size: var(--scale0);
    border-left: 1px solid var(--grey-light);
    margin-left: 0;
    margin-right: 0;
    padding-left: 2.4rem;
    line-height: 1.85;
}
