// #intro, #sobre{
//     display:flex;
//     justify-content:center;
//     align-items:center;
// }


#intro {
    // height: 60vh;
    padding-top: 30rem;
    padding-bottom: 10rem;
    overflow: hidden;
    width: 100%;
    position: relative;
    .hero {
        position: absolute;
        z-index: -1;
        float: right;
        right: -4%;
        top: 15rem;
        width: calc(100vw / 1.2);
        height: calc((587/881) * calc(100vw / 1.2));
        max-width: 1070px;
        max-height: 715.141px;
        @include bp(sm) {
            top: 8rem;
            right: 0%;
            width: 100vw;
            height: calc((587/881) * 100vw);
        }
    }
}
@for $i from 1 to 5 {
    .hero-blob_#{$i} {
        animation: wiggle #{$i * 2.5}s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;
        transition-delay: #{$i * 0.15}s;
        --x: 30px;
        --y: 10px;
        --x-initial: 0;
        --y-initial: var(--x-initial);
    }
} 


#sobre {
    padding-top: 20rem;
    padding-bottom: 20rem;
    // overflow-y: none;
    @include bp(sm) {
        padding-top: 0rem;
        padding-bottom: 10rem;
    }
    ul {
        padding-left: 1rem;
        li {
            &:before {
                content: "\2023";
                position: absolute;
                margin-left: -1.8rem;
          font-size: 2.8rem;
          line-height: 1.1;
        }
      }
    }
}
.shapes {
    margin-top: 60vh;
    width: 100%;

    position: absolute;

    @include bp(sm) {
        display: none;
    }
    
    @for $i from 1 to 9 {
        .shape-#{$i} {
            border-radius: 100px;
            position: absolute;
            z-index: -1;
            // animation: travel 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            @if $i == 1 {                
                background-color: var(--teal-dark);
                width: dynamic-size(33.9, null); 
                height: dynamic-size(9.0, null);
                left: 57%;
                --travel: -50vw;
            }
            @if $i == 2 {                
                background-color: var(--blue-dark);                
                width: dynamic-size(57.3, null); 
                height: dynamic-size(15.3, null);
                left: 15%;
                margin-top: 15rem;
                --travel: 80vw;
            }
            @if $i == 3 {                
                background-color: var(--cyan);
                width: dynamic-size(33.9, null); 
                height: dynamic-size(9.1, null);
                left: 72%;
                margin-top: 25rem;
                --travel: -50vw;
            }
            @if $i == 4 {                
                background-color: var(--teal);
                width: dynamic-size(33.9, null); 
                height: dynamic-size(9.1, null);
                left: -1%;
                margin-top: 30rem;
                --travel: 90vw;
            }
            @if $i == 5 {                
                background-color: var(--yellow);
                width: dynamic-size(57.3, null); 
                height: dynamic-size(15.3, null);
                left: 8%;
                margin-top: 40rem;
                --travel: 30vw;
            }
            @if $i == 6 {                
                background-color: var(--pink);
                width: dynamic-size(42.7, null); 
                height: dynamic-size(7.0, null);
                left: 80%;          
                margin-top: 35rem;
                --travel: -70vw;
            }
            @if $i == 7 {                
                background-color: var(--cyan);
                width: dynamic-size(31.5, null); 
                height: dynamic-size(11.6, null);
                left: 20%;
                margin-top: 50rem;
                --travel: 50vw;
            }
            @if $i == 8 {                
                background-color: var(--blue);
                width: dynamic-size(57.3, null); 
                height: dynamic-size(15.3, null);
                left: 60%;
                margin-top: 70rem;
                --travel: -70vw;
            }
            
        }
    }
}

#programa {
    margin-top: 5vh;
    margin-bottom: 20vh;
    .duration {
        margin-top: 3.5rem;
        @include bp(xs) {
            order: 1;
        }
        &::before {
        	position: absolute;
        	opacity: 0.31;
        	background: #ffefd0;
        	height: 25.9rem;
        	width: 75vw;
        	border-radius: 100rem;
        	content: "";
        	z-index: -1;
        	transform: translate(-50vw, -3rem);

        	@include bp(sm) {
        		width: 80vw;
                transform: translate(-40vw, -2rem);
                height: 25.5rem;
        	}
        	@include bp(xs) {
                transform: translate(-40vw, -2rem);
                height: 20.5rem;
                width: 120vw;
        	}
        }
    }
}

#inscricao {
    padding-bottom: 20rem;
    text-align: center;
    @include bp(xs) {
        text-align: left;
    }
    .one, .two, .three {
        margin-top: 5rem;
        &::before {
            opacity: 0.49;
            font-weight: 900;
            font-size: 30.8rem;
            color: #EDEFF3;
            position: absolute;
            z-index: -1;
            margin-left: -14.4rem;
            line-height: 0.9;
            @include bp(xs) {
                margin-left: 9.4rem;
                line-height: 0.7;
            }
        }
    }
    .one::before {
        content: "1";
    }
    .two::before {
        content: "2";
    }
    .three::before {
        content: "3";
    }
    .icon {
        svg{
            width: calc(8rem + (20.4 - 8) * ((100vw - 375px)/(1280 - 375)));
        }
    }
}

#contacto {
    // padding-top: 20rem;
    padding-bottom: 10rem;
    .icon {
      svg {
        width: calc(9rem + (20.4 - 9) * ((100vw - 375px)/(1280 - 375)));
      }
    }
    a {
        font-weight: 700;
    }
    // &::before {
    //     position: absolute;

    //     background: #0037A5;
    //     height: dynamic-size(30.7, null);
    //     width: 50vw;
    //     border-radius: 100rem;
    //     content: "";
    //     z-index: -1;
    //     --x-inital: 80vw;
    //     --y-inital: -30px;
    //     --x: 85vw;
    //     --y: -20px;
    //     transform: translate(var(--x-inital), var(--y-inital));
    //     animation: wiggle 4.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate;
    //     @include bp(xs) {
    //         width: 130vw;
    //         --x-inital: 70vw;
    //         --y-inital: -80px;
    //         --x: 75vw;
    //         --y: -70px;
    //     }
    // }

    .mapa {
        margin-top: 14rem;
        &::before {
            position: absolute;
            opacity: 0.31;
            background: #B6BCC9;
            height: 26.9rem;
            width: 70vw;
            border-radius: 100rem;
            content: "";
            z-index: -1;
            --x-inital: -50vw;
            --y-inital: 30px;
            --x: -45vw;
            --y: 20px;
            transform: translate(var(--x-inital), var(--y-inital));
            // animation: wiggle 4.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;
            @include bp(md) {
                // transform: translate(-90vw, 30px);
                width: 90vw;
            }
            @include bp(xs) {
                transform: translate(var(--x), var(--y));
                width: 120vw;
                // transform: translate(-40vw, 30px);
            }
        }
    }
}

.box {
    background: #fff;
    outline: #fff solid 6rem;
    @include bp(sm) {
        outline-width: 4rem;

    }
}

.oferta {
    background: #2C3D60;
    color: #AFFFFE;
    padding: 4px 8px;
    border-radius: 40px;
    margin-left: -8px;
}


@keyframes wiggle {
	from {
		transform: translate(var(--x-inital), var(--y-inital));
	}

	to {
		transform: translate(var(--x), var(--y));
	}
}

@keyframes travel {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(var(--travel));
	}
}