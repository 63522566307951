.success {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100vh;
    align-items:center;
    .success_envelop {
        transform-origin: 50% 50%;
        opacity: 0;
        transform: scale(0) translateY(50px);
        animation: envelopanim 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
    .check {
        transform: scale(0);
        transform-origin: 80% 20%;
        animation: checkanim 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.45s forwards;
    }
    p {
        // transform-origin: 50% 50%;
        opacity: 0;
        transform: translateY(50px);
        animation: envelopanim 1s cubic-bezier(0.19, 1, 0.22, 1) 0.25s forwards;
    }
}

@keyframes checkanim {
    0%{
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes envelopanim {
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}