footer {
    background-color: var(--blue-darker);
    padding-top: 9rem;
    padding-bottom: 6rem;
    width: 100%;
    overflow-y: hidden;
    @include bp(xs) {
        padding-top: 6rem;
    }
    h3 {
        color: var(--grey);
    }
    p{
        color: var(--grey-light);
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    .mini-shapes {
        width: 100%;
        height: 80%;
        z-index: -1;
        // background: pink;
        $colors: --teal,
        --yellow,
        --cyan,
        --pink,
        --teal-dark,
        --blue,
        --blue-dark;
        @for $i from 1 to 16 {
        	.mini_shape-#{$i} {
        		border-radius: 50px;
        		position: absolute;
                background: var(nth($colors, random(length($colors))));
        		// z-index: -1;
                animation: scatter 1.5s linear infinite;
                animation-delay: #{$i * 0.4}s;
                width: random(11)+px;
                height: 3px;
                margin-top: -5px;
                --x: #{random(300)}px;
                --y: #{random(200)}px;
            }
        }
    }
}

@keyframes scatter {
    from {
        transform: translate(0,0);
        opacity: 1; 
    }
    to {
        transform: translate(var(--x), var(--y));
        opacity: 0; 
    }
}